
import { faBoxArchive } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight, faCircleCheck, faCirclePlus, faCircleQuestion, faClock, faHourglassClock, faMessageLines, faMug, faRightFromBracket, faSackDollar } from '@fortawesome/pro-regular-svg-icons';
import { faMug as faSolidMug, faTriangleExclamation, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faHorizontalRule } from '@fortawesome/pro-thin-svg-icons';
import FA from './fontawesome';
import { IconPacks } from './IconPacks';

/**
 * Pack used for the sunkhronos library
 */
export const sunkhronosPack = FA.createIconPack(
    IconPacks.Sunkhronos,
    faHorizontalRule,
    faSackDollar,
    faArrowRight,
    faCirclePlus,
    faMessageLines,
    faSolidMug,
    faMug,
    faClock,
    faCircleCheck,
    faHourglassClock,
    faTriangleExclamation,
    faRightFromBracket,
    faCircleQuestion,
    faBoxArchive,
    faXmark,
);