
import { Button, Col, notification, Row } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { changeAppVersion, setPermanentNotifApi } from '../../store/actions/configurations';
import Network from '../../utils/network';
import { AppVersion } from '../../utils/types/generalTypes';
import { isDevMode, showNotif } from '../../utils/utils';

interface IProps {
    children: React.ReactNode;
}

type Props = IProps;

const CheckVersion = (props: Props) => {
    const versionLoading = useRef<boolean>(false)
    const history = useHistory()
    // Redux dispatch
    const dispatch = useDispatch()
    const api = notification
    const { pathname } = useLocation()
    const intl = useIntl();

    const getLang = useCallback(() => {
        const splitted_pathname = pathname.split('/')
        return (['fr', 'en', 'de', 'it'].includes(splitted_pathname[1])) ? splitted_pathname[1] : 'fr'
    }, [pathname])

    useEffect(() => {
        dispatch(setPermanentNotifApi(api))
    }, [api, dispatch])

    const goTo = useCallback((link: string) => history.push(`/${getLang()}${link}`), [history, getLang])



    const localAppVersion = useRef<AppVersion | undefined>(undefined)
    //const interval = useRef<NodeJS.Timeout | undefined>(undefined);
    const interval = useRef<ReturnType<typeof setInterval> | undefined>(undefined)

    const refreshAppVersion = useCallback(() => {
        const appName: string | undefined | JSX.Element = process.env.REACT_APP_NAME;
        const version: string | undefined = process.env.REACT_APP_VERSION;
        if (appName === undefined) {
            showNotif(api, intl.formatMessage({ defaultMessage: 'Unable to check for a new version. Please correctly setup App Version' }), "error")
            return
        }
        if (version === undefined) {
            showNotif(api, intl.formatMessage({ defaultMessage: 'Unable to check for a new version. Please correctly setup App Version' }), "error")
            return
        }

        if (!versionLoading.current) {
            versionLoading.current = true

            Network.getAppVersion(appName)
                .then(
                    (response: AppVersion) => {

                        if ((localAppVersion.current !== undefined && localAppVersion.current.version !== response.version) ||
                            (localAppVersion.current === undefined && version !== undefined && version !== response.version)) {
                            if (!isDevMode()) {
                                showNotif(
                                    api,
                                    intl.formatMessage({ defaultMessage: 'An update is available' }),
                                    "update",
                                    <Row gutter={[10, 0]}>
                                        <Col xs={{ span: 10 }} md={{ span: 10 }}>{intl.formatMessage({ defaultMessage: 'From version' })}{':'}</Col>
                                        <Col xs={{ span: 14 }} md={{ span: 14 }}> {`${version}`}</Col>
                                        <Col xs={{ span: 10 }} md={{ span: 10 }}>{intl.formatMessage({ defaultMessage: 'To version' })}{':'}</Col>
                                        <Col xs={{ span: 14 }} md={{ span: 14 }}> {`${response.version}`}</Col>
                                        <Col xs={{ span: 24 }} md={{ span: 24 }}><Button onClick={() => goTo('/help/version')} style={{ border: 'none', boxShadow: 'none' }}>{intl.formatMessage({ defaultMessage: 'See changelogs' })}</Button></Col>
                                    </Row>,
                                    intl)
                            }
                        }
                        if (!isEqual(localAppVersion.current, response)) {
                            localAppVersion.current = response
                            dispatch(changeAppVersion(response))
                        }
                        versionLoading.current = false
                    })
                .catch(() => {
                    versionLoading.current = false
                    showNotif(api, intl.formatMessage({ defaultMessage: 'An error occurred while checking for a new version' }), "error")

                });
        }

    }, [api, intl, goTo, dispatch])

    const launchInterval = useCallback((time = 3600000) => {
        if (interval.current === undefined) {
            interval.current = setInterval(() => {
                refreshAppVersion()
            }, time);
        }

    }, [refreshAppVersion])

    useEffect(() => {
        return () => {
            if (interval !== undefined)
                clearInterval(interval.current as NodeJS.Timeout)
            interval.current = undefined
        };
    }, []);

    useEffect(() => {
        if (localAppVersion.current === undefined) {
            refreshAppVersion()
        }
        if (interval.current === undefined) {
            launchInterval(900000)
        }

    }, [refreshAppVersion, launchInterval])

    return (
        <>

            {
                props.children
            }
        </>
    );
}

export default CheckVersion;
